<template>
  <div class="bg">
    <div class="top">
      <img src="../../../assets/img/inquiry_bg.png" alt="" />
    </div>
    <div class="bot">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in list" class="mylist" :key="item" @click="goinfo">
            <img src="../../../assets/img/inquiry_10.png" alt="" srcset="" class="left" />
            <div class="right">
              <h1>北京水滴互保科技有限公司</h1>
              <p class="sl">
                北京水滴互保科技有限公司北京水滴互保科技有限公司北京水滴互保科技有限公司北京水滴互保科技有限公司北京水滴互保科技有限公司北京水滴互保科技有限公司北京水滴互保科技有限公司北京水滴互保科技有限公司
              </p>
              <div>
                <img src="../../../assets/img/inquiry_10.png" alt="" srcset="" />
                已救助 <span> 384,059,7</span>人
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  methods: {

    goinfo(){
       this.$router.push({ name: "fundinfo" });

    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  .top {
    height: 100px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bot {
    padding: 16px;
    .mylist {
      background: #fff;
      height: 158px;
      margin-bottom: 8px;
      display: flex;
      padding: 16px;
      .left {
        display: block;
        width: 80px;
        height: 80px;
        margin-right: 16px;
      }
      .right {
        h1 {
          font-size: 16px;
          color: rgba(45, 45, 45, 1);
          margin-bottom: 8px;
        }
        p {
          min-height: 57px;
          margin-bottom: 16px;
        }
        img{
          width: 24px;
          height: 24px;
              vertical-align: middle;
        }
        span {
          color: rgba(251, 81, 81, 1);
        }
      }
    }
  }
}
</style>
